import React from "react";
import { graphql } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";
import Layout from "../components/Layout";
import MetaComponents from "../components/seo/MetaComponents";
import Cooperation from "../components/Cooperation";
import MediaLogos from "../components/MediaLogos";
import BreadcrumbList from "../components/seo/BreadcrumbList";
import { ContentWrapper } from "../assets/styles/base";
import PressroomArticles from "../components/PressroomArticles";

const Presse = () => {
    const { t } = useTranslation();

    return (
        <Layout>
            <MetaComponents title={t("page.meta.title")} description={t("page.meta.description")} />
            <ContentWrapper hasMarginTop>
                <h1>Presse</h1>
                <PressroomArticles index={0} />
            </ContentWrapper>
            <MediaLogos hasGreyBg hasMarginTop />
            <ContentWrapper hasMarginTop>
                <PressroomArticles index={1} />
            </ContentWrapper>
            <Cooperation hasGreyBg hasMarginTop />
            <ContentWrapper hasMarginTop>
                <PressroomArticles index={2} />
            </ContentWrapper>
            <BreadcrumbList page={"presse"}></BreadcrumbList>
        </Layout>
    );
};

export default Presse;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(
            filter: {
                ns: { in: ["header", "footer", "page.presse", "pressroomArticles"] }
                language: { eq: $language }
            }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
