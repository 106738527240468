import React from "react";
import styled from "styled-components";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { breakpoints } from "../constants/breakpoints";
import { urls } from "../constants/urls";

const PressroomArticles = ({ pressroomArticlesResource = "articles.sections", index = 0 }) => {
    const { t } = useTranslation();

    const getLink = (link) => {
        if (link === "urls.survey") {
            return urls.survey;
        }

        return link;
    };

    const isPdf = (link) => {
        if (!link.includes(".pdf")) {
            return false;
        }
        return true;
    };

    const getArticleContent = (articleKey, article) => {
        return (
            <React.Fragment key={articleKey}>
                <div className="articleImageContainer">
                    <img src={article[2]} alt={article[0]} width="452" height="256" />
                </div>
                <div className="articleContentContainer">
                    <h2 className="articleHeading">{article[0]}</h2>
                    <p>{article[1]}</p>
                    {article[3] !== "" ? (
                        <p>
                            {isPdf(getLink(article[3])) ? (
                                <a className="btn" href={getLink(article[3])} download>
                                    {article[4]}
                                </a>
                            ) : (
                                <a className="btn" href={getLink(article[3])}>
                                    {article[4]}
                                </a>
                            )}
                        </p>
                    ) : null}
                </div>
            </React.Fragment>
        );
    };

    const articlesTranslationResources = t(pressroomArticlesResource, { returnObjects: true });

    return articlesTranslationResources && articlesTranslationResources.length > index
        ? articlesTranslationResources[index].articles.map((article, articleKey) => {
              return <ArticleWrapper key={articleKey}>{getArticleContent(articleKey, article)}</ArticleWrapper>;
          })
        : articlesTranslationResources.map((section, sectionKey) => {
              return (
                  <ArticleWrapper key={sectionKey}>
                      {section.articles.map((article, articleKey) => {
                          return getArticleContent(articleKey, article);
                      })}
                  </ArticleWrapper>
              );
          });
};

const ArticleWrapper = styled.article`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    &:not(:first-child) {
        margin-top: 60px;
    }

    .articleImageContainer {
        flex: 1 0 50%;
        max-width: 452px;
    }

    .articleContentContainer {
        flex: 1 0 44%;
        margin-left: 6%;
    }

    .articleHeading {
        color: #000000;
        font-size: 25px;
        font-weight: 600;
    }

    img {
        width: 100%;
        height: auto;
    }

    @media (max-width: ${breakpoints.mobileBigMax}) {
        .articleContentContainer,
        .articleImageContainer {
            flex: 1 0 100%;
            margin-left: auto;
            margin-right: auto;
        }
        .articleImageContainer {
            padding-bottom: 10px;
        }
        .articleContentContainer {
            max-width: 452px;
        }
    }
    @media (max-width: ${breakpoints.tabletMax}) {
        align-items: center;
    }
`;

export default PressroomArticles;
