import * as packageJson from "./../../package.json";

export const urls = {
    survey: "https://" + process.env.GATSBY_SURVEY_HOST + ".miracl.at/survey/",
    kreditrechner: process.env.GATSBY_SITE_URL + "/dienstleistungen/kreditrechner/",
};

export const extendUrlWithSourceVersion = function (url) {
    if (url) {
        const source = packageJson.name;
        const version = packageJson.version;
        const shouldAppendParam = url.includes("?");
        const delimiter = shouldAppendParam ? "&" : "?";
        const params = `packageVersion=${version}&source=${source}`;

        return `${url}${delimiter}${params}`;
    }

    return url;
};
